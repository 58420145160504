import NorthStarThemeProvider from '@aws-northstar/ui/components/NorthStarThemeProvider'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Route, Routes, useNavigate } from 'react-router-dom'

import RootRedirect from './helper/RootRedirect'
import withAuthRequired from './helper/withAuthRequired'
import AppLayout from './components/AppLayout'
import ComponentRBAC from './components/ComponentRBAC'
import DefaultDashboard from './components/Dashboard'
import DevOkkar from './components/DevOkkar'
import JWT from './components/JWT'
import OrderForm from './components/OrderForm'
import Review from './components/Review'
import AccessManagement from './pages/access-management'
import AccessManagementDetails from './pages/access-management/[userEmail]'
import NewAccessManagementForm from './pages/access-management/new'
import Accounts from './pages/accounts'
import AccountDetails from './pages/accounts/[id]'
import AccountCreationForm from './pages/accounts/new'
import GcpProjects from './pages/gcpProjects'
import GcpProjectDetails from './pages/gcpProjects/[id]'
import GcpProjectCreationForm from './pages/gcpProjects/new'
import Dashboard from './pages/dashboard'
import Projects from './pages/projects'
import ProjectDetails from './pages/projects/[id]'
import EditProjectForm from './pages/projects/edit'
import EditEphemeralForm from './pages/projects/ephemeral/edit'
import NewProjectForm from './pages/projects/new'
import PlatformOnboarding from './pages/platforms'
import NewPlatformForm from './pages/platforms/new'
import ApplicationOnboarding from './pages/application'
import NewApplicationForm from './pages/application/new'
import EditPlatformForm from './pages/platforms/edit'
import PlatformDetails from './pages/platforms/[id]'
import ApplicationDetails from './pages/application/[id]'
import EditApplicationForm from './pages/application/edit'
import NewEphemeralForm from './pages/projects/ephemeral'
import RhocpTenants from './pages/rhocpTenants'
import RhocpTenantCreationForm from './pages/rhocpTenants/new'
import RhocpTenantDetails from './pages/rhocpTenants/[id]'
import SslCerts from './pages/projects/[id]/sslCerts'
import NewSslCerts from './pages/projects/[id]/sslCerts/new'
import SslCertDetails from './pages/projects/[id]/sslCerts/[id]'
import NewMicroserviceForm from './pages/microservices/new'
import MicroserviceOnboarding from './pages/microservices'
import MicroserviceDetails from './pages/microservices/[id]'
import EditMicroserviceForm from './pages/microservices/edit'
import ApiOnboarding from './pages/apis'
import ApiDetails from './pages/apis/[id]'
import EditApiForm from './pages/apis/edit'
import NewApiForm from './pages/apis/new'

const oktaIssuer = process.env.REACT_APP_OKTA_ISSUER

if (!oktaIssuer) {
	throw new Error('REACT_APP_OKTA_ISSUER is not defined')
}

const oktaAuth = new OktaAuth({
	clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
	issuer: oktaIssuer,
	redirectUri: window.location.origin + '/index.html',
	scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
	postLogoutRedirectUri: window.location.origin,
	pkce: true
})

const queryClient = new QueryClient()

const App = () => {
	const navigate = useNavigate()
	const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
		navigate(toRelativeUrl(originalUri || '/', window.location.origin))
	}

	return (
		<QueryClientProvider client={queryClient}>
			<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
				<NorthStarThemeProvider>
					<AppLayout>
						<Routes>
							<Route path="/" element={<RootRedirect />} />
							<Route path="/index.html" element={<RootRedirect />} />

							{withAuthRequired('/dashboard', <Dashboard />)}
							{withAuthRequired('/default-dashboard', <DefaultDashboard />)}
							{withAuthRequired('/createOrder', <OrderForm />)}
							{withAuthRequired('/jwt', <JWT />)}
							{withAuthRequired('/componentRBAC', <ComponentRBAC />)}
							{withAuthRequired('/DevOkkar', <DevOkkar />)}
							{withAuthRequired('/review', <Review />)}

							{withAuthRequired('/accounts', <Accounts />)}
							{withAuthRequired('/accounts/new', <AccountCreationForm />)}
							{withAuthRequired('/accounts/:id', <AccountDetails />, [
								'accounts:*:read'
							])}

							{withAuthRequired('/gcpProjects', <GcpProjects />)}
							{withAuthRequired('/gcpProjects/new', <GcpProjectCreationForm />)}
							{withAuthRequired('/gcpProjects/:id', <GcpProjectDetails />, [
								'gcpProjects:*:read'
							])}

							{withAuthRequired('/rhocpTenants', <RhocpTenants />)}
							{withAuthRequired(
								'/rhocpTenants/new',
								<RhocpTenantCreationForm />
							)}
							{withAuthRequired('/rhocpTenants/:id', <RhocpTenantDetails />)}

							{withAuthRequired('/projects', <Projects />)}
							{withAuthRequired('/projects/new', <NewProjectForm />)}
							{withAuthRequired('/projects/ephemeral', <NewEphemeralForm />)}
							{withAuthRequired('/projects/edit/:id', <EditProjectForm />, [
								'projects:*:create'
							])}
							{withAuthRequired(
								'/projects/ephemeral/edit/:id',
								<EditEphemeralForm />,
								['projects:*:create']
							)}

							{withAuthRequired('/projects/:id', <ProjectDetails />, [
								'projects:*:read'
							])}

							{withAuthRequired('/projects/:id/sslCerts', <SslCerts/>, [
								'projects:*:read'
							])}

							{withAuthRequired('/projects/:id/sslCerts/:resourceId', <SslCertDetails/>)}

							{withAuthRequired('projects/:id/sslCerts/new', <NewSslCerts/>, [
								'projects:*:create', 'projects:*:read'
							])}

							{withAuthRequired('/platforms', <PlatformOnboarding />)}
							{withAuthRequired('/platforms/new', <NewPlatformForm />)}
							{withAuthRequired('/platforms/edit/:id', <EditPlatformForm />, [
								'platforms:*:create'
							])}
							{withAuthRequired('/platforms/:id', <PlatformDetails />, [
								'platforms:*:read'
							])}

							{withAuthRequired('/applications', <ApplicationOnboarding />)}
							{withAuthRequired('/applications/new', <NewApplicationForm />)}
							{withAuthRequired('/applications/:id', <ApplicationDetails />, [
								'applications:*:read'
							])}
							{withAuthRequired(
								'/applications/edit/:id',
								<EditApplicationForm />,
								['applications:*:create']
							)}

							{withAuthRequired('/microservices', <MicroserviceOnboarding />)}
							{withAuthRequired('/microservices/new', <NewMicroserviceForm />)}
							{withAuthRequired('/microservices/:id', <MicroserviceDetails />, [
								'microservices:*:read'
							])}
							{withAuthRequired(
								'/microservices/edit/:id',
								<EditMicroserviceForm />,
								['microservices:*:create']
							)}

							{withAuthRequired('/apis', <ApiOnboarding />)}
							{withAuthRequired('/apis/new', <NewApiForm />)}
							{withAuthRequired('/apis/:id', <ApiDetails />, ['apis:*:read'])}
							{withAuthRequired('/apis/edit/:id', <EditApiForm />, [
								'apis:*:create'
							])}

							{withAuthRequired('/access-management', <AccessManagement />, [
								'useraccess:*:read'
							])}
							{withAuthRequired(
								'/access-management/new',
								<NewAccessManagementForm />,
								['useraccess:*:create']
							)}
							{withAuthRequired(
								'/access-management/:userEmail',
								<AccessManagementDetails />,
								['useraccess:*:read']
							)}
						</Routes>
					</AppLayout>
				</NorthStarThemeProvider>
			</Security>
		</QueryClientProvider>
	)
}

export default App
