import { FC } from 'react'
import { KeyValuePairs } from '@aws-northstar/ui'
import { CSSProperties } from 'react'
import {
	Box,
	ColumnLayout,
	Container,
	ExpandableSection,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import CONSTANTS from '../../../constants'

type AccessDetailsProps = {
	microserviceName: string
	applicationName: {
		label: string
		value: string
	}
}

const AccessDetails: React.FC<AccessDetailsProps> = ({
	microserviceName,
	applicationName
}) => {
	const baseUrl = `https://gitlab.com/globetelecom/infrastructure/gcp/application/atlas/core-pipelines/atlas-microservices/${applicationName.label}`
	const generalDetailsKeyValuePairsItems = [
		[
			{
				label: '',
				value: (
					<a
						href={`${baseUrl}/atlas-microservice-${microserviceName}`}
						target="_blank"
						rel="noopener noreferrer"
						style={{ textDecoration: 'none' }}
					>
						{`Gitlab Microservice SubGroup`}
					</a>
				)
			}
		]
	]

	// const compassLinkKeyValuePairsItems = [
	// 	[
	// 		{
	// 			label: '',
	// 			value: (
	// 				<a
	// 					href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/atlas_101/`}
	// 					target="_blank"
	// 					rel="noopener noreferrer"
	// 					style={{ textDecoration: 'none' }}
	// 				>
	// 					{`Atlas 101`}
	// 				</a>
	// 			)
	// 		}
	// 	],
	// 	[
	// 		{
	// 			label: '',
	// 			value: (
	// 				<a
	// 					href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/meet_criteria/`}
	// 					target="_blank"
	// 					rel="noopener noreferrer"
	// 					style={{ textDecoration: 'none' }}
	// 				>
	// 					{`Meet Criteria`}
	// 				</a>
	// 			)
	// 		}
	// 	],
	// 	[
	// 		{
	// 			label: ' ',
	// 			value: (
	// 				<a
	// 					href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/application-onboarding/application_onboarding/`}
	// 					target="_blank"
	// 					rel="noopener noreferrer"
	// 					style={{ textDecoration: 'none' }}
	// 				>
	// 					{`Application onboarding`}
	// 				</a>
	// 			)
	// 		}
	// 	]
	// ]

	return (
		<Container header={<Header variant="h3">Access Details</Header>}>
			{/* Commenting to update here */}
			<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
				For accesses to the links below, you may reach out to Platform
				Engineering group (isg-ceso-platengr@globe.com.ph)
			</p>
			<ColumnLayout columns={2} variant="text-grid">
				<ExpandableSection headerText="What's Next?">
					<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
					You can check the merge request for your microservice in the link.
					</p>
					<ColumnLayout columns={2} variant="text-grid">
						<KeyValuePairs
							key={'generalDetails'}
							items={generalDetailsKeyValuePairsItems}
						/>
					</ColumnLayout>
				</ExpandableSection>
				{/* <ExpandableSection headerText="Resource Links">
					<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px' }}>
					Here are some helpful links that may help you in developing your api.
					</p>
					<ColumnLayout columns={2} variant="text-grid">
						<KeyValuePairs key={''} items={compassLinkKeyValuePairsItems} />
					</ColumnLayout>
				</ExpandableSection> */}
			</ColumnLayout>
		</Container>

		
	)
}

export default AccessDetails
