import React, { FC } from 'react'
import Box from '@cloudscape-design/components/box'
import { Alert, Icon, SpaceBetween } from '@cloudscape-design/components'
import { ApiRejection } from '../../../types/apis'

const TS_OFFSET = 60000

interface CommentListProps {
	comments: Array<ApiRejection> | []
}

const CommentsOverviewLocal: FC<CommentListProps> = ({ comments }) => {
	const convertUTCDateToLocalDate = (beDate: string) => {
		let date = new Date(beDate)
		let newDate = new Date(
			date.getTime() - date.getTimezoneOffset() * TS_OFFSET
		)
		return newDate
	}
	return (
		<SpaceBetween direction="vertical" size="m">
			{comments && comments.length > 0 ? (
				comments.map((cmt) => {
					return (
						<Box color="text-body-secondary" key={cmt.timestamp}>
							<div>
								{`${cmt.commenterID} on ${convertUTCDateToLocalDate(
									cmt.timestamp
								).toLocaleTimeString()} ${convertUTCDateToLocalDate(
									cmt.timestamp
								).toDateString()}`}
							</div>
							<div className="col">
								<Icon name="contact" size="inherit" variant="link" />{' '}
								{cmt.comment}
							</div>
						</Box>
					)
				})
			) : (
				<Box color="text-body-secondary" key={new Date().getTime()}>
					<Alert statusIconAriaLabel="Info" header="API Request Comments">
						You're All Caught Up!
					</Alert>
				</Box>
			)}
		</SpaceBetween>
	)
}

const CommentsOverview = React.memo(CommentsOverviewLocal)
export default CommentsOverview
