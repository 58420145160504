import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween,
	Textarea
} from '@cloudscape-design/components'
import Icon from '@cloudscape-design/components/icon'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import FormInputArea from '../../../components/ControlledFormInputs/TextArea'

import { useNewApiFormStore } from '../../../stores/apis/newApiFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewApi from '../../../hooks/mutate/useCreateNewApi'
import useApprovedGcpProjects from '../../../hooks/query/useApprovedGcpProjects'
import CONSTANTS from '../../../constants'
import { gcpProject } from '../../../types/gcpProject'
import type { ApiRequest } from '../../../types/apis'
import api from '../../../api'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import useMicroservices from '../../../hooks/query/useMicroservices'
import Ajv from 'ajv'

const MICROSERVICE_LIST = [
	{ label: 'microservice1', value: 'microservice1' }
]

const METHOD_LIST = [
	{ label: 'GET', value: 'GET' },
	{ label: 'POST', value: 'POST' },
	{ label: 'PUT', value: 'PUT' },
	{ label: 'PATCH', value: 'PATCH' },
	{ label: 'DELETE', value: 'DELETE' }
]

const NewApiForm: FC = () => {

	const ajv = new Ajv();

	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const [microserviceOptions, setMicroserviceOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: microservices } = useMicroservices()

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewApiFormStore()

	const {
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	} = useGenericTagsManagementFormStore()

	const { mutate: createNewApi, isLoading } = useCreateNewApi()
	const { mutate: createTags, isLoading: isCreateTagsLoading } =
		useCreateNewTags()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	interface envOpt {
		label: string
		value: string
	}

	const [isInheritTagsChecked, setInheritTagsChecked] = useState(false)

	const [popFields, setPopFields] = useState(false)

	useEffect(() => {
		if (microservices && microservices.length > 0) {
			const filteredMicroservices = microservices.filter(
				(microservice) => microservice.status === 'APPROVED'
			)
			const uniqueMicroservices: envOpt[] = []
			const microserviceNames = new Set()

			filteredMicroservices.forEach((microservice) => {
				if (!microserviceNames.has(microservice.microserviceName)) {
					microserviceNames.add(microservice.microserviceName)
					uniqueMicroservices.push({
						label: microservice.microserviceName,
						value: microservice.microserviceID
					})
				}
			})

			const extractedOptions = uniqueMicroservices
			setMicroserviceOptions(extractedOptions)
		}
	}, [microservices])

	// useEffect(() => {
	// 	if (formValues.gcpProjectID !== undefined) {
	// 		setGcpProjectIdFilled(true)
	// 	} else {
	// 		setGcpProjectIdFilled(false)
	// 	}
	// }, [formValues.gcpProjectID])

	useEffect(() => {
		const getProjectWithTags = async (gcpProjectID: {
			label: string
			value: string
		}) => {
			let response = await api.gcpProjects.getSinglegcpProject(
				gcpProjectID.value,
				true
			)
			let project = response.body[0] as gcpProject
			console.log(project)

			setTagFormValues('platformName', project.tags?.details.platformName)
			setTagFormValues('group', project.tags?.details.group)
			setTagFormValues(
				'fundingSourceType',
				project.tags?.details.fundingSourceType
			)
			setTagFormValues('WBSCode', project.tags?.details.WBSCode)
			setTagFormValues('costCenter', project.tags?.details.costCenter)
			setTagFormValues('projectName', gcpProjectID.label)

			setFormValues('wbsCode', project.tags?.details.WBSCode)
			setFormValues('costCenter', project.tags?.details.costCenter)
			setFormValues('group', project.tags?.details.group)
			setValue('group', project.tags?.details.group)
		}
		if (isInheritTagsChecked) {
			console.log(formValues.gcpProjectID)
			getProjectWithTags(formValues.gcpProjectID)
		} else {
			clearTagFormValues()

			setFormValues('wbsCode', '')
			setFormValues('costCenter', '')
			setFormValues('group', '')
			setValue('group', '')
		}
	}, [
		formValues.gcpProjectID,
		setTagFormValues,
		setValue,
		isInheritTagsChecked,
		clearTagFormValues,
		setFormValues
	])

	useEffect(() => {
		if (tagFormValues.WBSCode !== '') {
			setFormValues('wbsCode', tagFormValues.WBSCode)
		}

		if (tagFormValues.costCenter !== '') {
			setFormValues('costCenter', tagFormValues.costCenter)
		}
	}, [tagFormValues.WBSCode, tagFormValues.costCenter, setFormValues])

	useEffect(() => {
		if (formValues.platformOwner !== '') {
			setTagFormValues('platformOwner', formValues.platformOwner)
		}

		if (formValues.platformName !== '') {
			setTagFormValues('platformName', formValues.platformName)
		}

		if (formValues.systemAlias !== '') {
			setTagFormValues('systemAlias', formValues.systemAlias)
		}
	}, [formValues, setTagFormValues])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const api: ApiRequest = {
			requestorID: thisUserEmail,
			// tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		createTags(newTag, {
			onSuccess: () => {
				createNewApi(api, {
					onSuccess: () => {
						navigate('/apis')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}
	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	useEffect(() => {
		if (formValues?.platformType?.value === 'standard') {
			setPopFields(true)
		} else {
			setPopFields(false)
		}
	}, [formValues?.platformType?.value, setPopFields, formValues])

	// Effect to trigger schema validation when either field changes
	useEffect(() => {
		if (formValues.requestDataTypes || formValues.mockRequest) {
		  trigger(['requestDataTypes', 'mockRequest']);
		}
	  }, [formValues.requestDataTypes, formValues.mockRequest, trigger]);
	  
	  useEffect(() => {
		if (formValues.responseDataTypes || formValues.mockResponse) {
		  trigger(['responseDataTypes', 'mockResponse']);
		}
	  }, [formValues.responseDataTypes, formValues.mockResponse, trigger]);

	  // Process Schema
	  const processSchemaProperties = (schema: any) => {
		const properties: any = {};
		const required: string[] = Object.keys(schema);
  
		Object.entries(schema).forEach(([key, type]) => {
		  // Basic types: string, number, etc.
		  if (typeof type === 'string') {
			properties[key] = { 
			  type: type.toLowerCase() === 'integer' ? 'integer' : type.toLowerCase() 
			};
		  }
		  // Array type
		  else if (Array.isArray(type)) {
			if (type.length === 1) {
			  // Array of objects
			  if (typeof type[0] === 'object' && type[0] !== null) {
				const { properties: nestedProps, required: nestedRequired } = processSchemaProperties(type[0]);
				properties[key] = {
				  type: 'array',
				  items: {
					type: 'object',
					properties: nestedProps,
					required: nestedRequired,
					additionalProperties: false
				  }
				};
			  } 
			  // Array of basic types
			  else if (typeof type[0] === 'string') {
				properties[key] = {
				  type: 'array',
				  items: { 
					type: type[0].toLowerCase() === 'integer' ? 'integer' : type[0].toLowerCase() 
				  }
				};
			  }
			} else {
			  throw new Error(`Invalid array definition for key "${key}"`);
			}
		  }
		  // Object type: nested schema
		  else if (typeof type === 'object' && type !== null) {
			const { properties: nestedProps, required: nestedRequired } = processSchemaProperties(type);
			properties[key] = {
			  type: 'object',
			  properties: nestedProps,
			  required: nestedRequired,
			  additionalProperties: false
			};
		  } else {
			throw new Error(`Invalid type definition for key "${key}": ${type}`);
		  }
		});
  
		return { properties, required };
	  };
	  // Validate Schema
	  const validateSchema = (value: string, mockData: string, isMockData: boolean) => {
		try {
		  const schemaJson = JSON.parse(value);
		  const mockJson = JSON.parse(mockData);
	  
		  // Build a valid JSON schema
		  const { properties, required } = processSchemaProperties(schemaJson);
		  const jsonSchema = {
			type: 'object',
			properties,
			required,
			additionalProperties: false,
		  };
	  
		  const validate = ajv.compile(jsonSchema);
		  const isValid = validate(mockJson);
	  
		  if (!isValid) {			
			const errorMessages = validate.errors?.map(error => {
			  if (isMockData && error.keyword === 'type') {
				return `${error.instancePath.slice(1)} should be ${error.params.type}`;
			  }
			});
			return `${errorMessages?.join(', ')}`;
		  }	  
		  
		  return true;

		} catch (error: any) {
		  return `Error: Please check your schema and mock data`;
		}
	  };

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading || isCreateTagsLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Create Endpoint
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<ColumnLayout columns={2} variant="default">
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<div style={{ width: '650px' }}>
									<FormSelect
										label="Microservice"
										name="microserviceName"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={microserviceOptions}
										placeholder="Select Microservice Name"
									/>
								</div>
								<div style={{ marginLeft: '10px', marginTop: '25px' }}>
									<Popover
										fixedWidth
										header="Platform GCP Project ID tip"
										position="right"
										triggerType="custom"
										content={
											<p>
												Please see guide on how to enter your GCP Project ID:
												<a
													href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
													target="_blank"
													rel="noopener noreferrer"
													aria-label="Plus"
													style={{
														marginLeft: '10px',
														marginTop: '25px'
													}}
												>
													LINK
												</a>
											</p>
										}
									>
										<Icon name="status-info" />
									</Popover>
								</div>
							</div>
							<div></div>
							<FormInput
								name="apiName"
								label="Name"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Display Name of API"
								description="Max 20 characters"
								rules={{
									required: 'Please enter API name',
									minLength: {
										value: 5,
										message: 'Minumum length of 5 characters'
									},
									pattern: {
										value: /^[a-z0-9]+$/,
										message:
											'API name should be lowercase only and whitespaces are not allowed.'
									}
								}}
							/>
							<FormInput
								name="apiEndpoint"
								label="Path"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Enter Endpoint Path"
								description="The endpoint URL/path"
							/>
							<FormSelect
								label="HTTP Method"
								name="httpMethod"
								control={control}
								rules={{
									required: 'Please Select Option'
								}}
								setFormValues={setFormValues}
								options={METHOD_LIST}
								placeholder="Select HTTP Method"
							/>
							<FormInput
								name="apiStatus"
								label="Status"
								type="text"
								control={control}
								setFormValues={setFormValues}
								placeholder="Design"
								disabled
							/>
						</ColumnLayout>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<label style={{ fontWeight: '600', marginBottom: '5px' }}>
								Description
							</label>
							<Textarea
								onChange={({ detail }) => setFormValues('description',detail.value)}
								name='description'
								value={formValues.description}
								placeholder="Enter description of Endpoint"
							/>
						</div>
						<ColumnLayout columns={2} variant="default">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Request Data Types"
									name="requestDataTypes"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.mockRequest) return true;
												return validateSchema(value, formValues.mockRequest, false);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Request"
									name="mockRequest"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter mock request data"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.requestDataTypes) return true;
												return validateSchema(formValues.requestDataTypes, value, true);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Response Data Types"
									name="responseDataTypes"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.mockResponse) return true;
												return validateSchema(value, formValues.mockResponse, false);
											}
										}
									}}
								/>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<FormInputArea
									label="Mock Response"
									name="mockResponse"
									control={control}
									setFormValues={setFormValues}
									placeholder="Enter Request Structure as JSON object with data type as a value"
									rules={{
										validate: {
											jsonFormat: (value) => {
												try {
													if (!value) return true;
													JSON.parse(value);
													return true;
												} catch {
													return "Please enter valid JSON format";
												}
											},
											schemaValidation: (value) => {
												if (!value || !formValues.responseDataTypes) return true;
												return validateSchema(formValues.responseDataTypes, value, true);
											}
										}
									}}
								/>
							</div>
						</ColumnLayout>
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewApiForm
