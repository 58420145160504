import {
	Box,
	Button,
	Checkbox,
	ColumnLayout,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween,
	Tiles
} from '@cloudscape-design/components'
import Icon from '@cloudscape-design/components/icon'
import RadioGroup from '@cloudscape-design/components/radio-group'

import { useOktaAuth } from '@okta/okta-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormSelect from '../../../components/ControlledFormInputs/Select'

import { useNewMicroserviceFormStore } from '../../../stores/microservices/newMicroserviceFormStore'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import useCreateNewMicroservice from '../../../hooks/mutate/useCreateNewMicroservice'
import CONSTANTS from '../../../constants'
import { gcpProject } from '../../../types/gcpProject'
import type { MicroserviceRequest } from '../../../types/microservices'
import api from '../../../api'
import { getTagsApprovalObject, TagsRequest } from '../../../types/tags'
import { v4 as uuidv4 } from 'uuid'
import useCreateNewTags from '../../../hooks/mutate/useCreateNewTags'
import useApplications from '../../../hooks/query/useApplications'

const CODE_TEMPLATE_LIST = [
	// { label: 'NodeJS', value: 'nodejs' },
	{ label: 'NodeJS + CXS', value: 'cxs-nodejs' }
]

const MICROSERVICE_ENDPOINT_LIST = [
	{ label: 'Internal', value: 'internal' },
	{ label: 'External', value: 'external' },
	{ label: 'Internal + External', value: 'both' }
]

const NewMicroserviceForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const [applicationOptions, setApplicationOptions] = useState<
		{ label: string; value: string }[]
	>([])
	const { data: applications } = useApplications()

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewMicroserviceFormStore()

	const {
		formValues: tagFormValues,
		actions: {
			setFormValues: setTagFormValues,
			clearFormValues: clearTagFormValues
		}
	} = useGenericTagsManagementFormStore()

	const { mutate: createNewMicroservice, isLoading } =
		useCreateNewMicroservice()
	const { mutate: createTags, isLoading: isCreateTagsLoading } =
		useCreateNewTags()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid },
		watch
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})

	// const [isInheritTagsChecked, setInheritTagsChecked] = useState(false)
	const [showForm, setShowForm] = useState(false)
	// const [wbsCode, setWbsCode] = useState<{
	// 	wbsCode: string
	// 	wbsCodeId: number | null
	// }>({
	// 	wbsCode: '',
	// 	wbsCodeId: 0
	// })

	const [costCenterPlaceholder, setCostCenterPlaceholder] = useState("Add Cost Center")
	const [wbsCodePlaceholder, setWbsCodePlaceholder] = useState("Add WBS Code")

	interface envOpt {
		label: string
		value: string
	}

	// const [popFields, setPopFields] = useState(false)

	useEffect(() => {
		if (applications && applications.length > 0) {
			const filteredApplications = applications.filter(
				(application) => application.status === 'APPROVED'
			)
			const uniqueApplications: envOpt[] = []
			const applicationNames = new Set()

			filteredApplications.forEach((application) => {
				if (!applicationNames.has(application.details?.applicationName)) {
					applicationNames.add(application.details?.applicationName)
					uniqueApplications.push({
						label: application.details?.applicationName,
						value: application.applicationID
					})
				}
			})

			const extractedOptions = uniqueApplications

			setApplicationOptions(extractedOptions)
		}
	}, [applications])

	useEffect(() => {
		const subscription = watch((value) => {
			const selectedApplicationId = value.applicationName?.value; 
			if (selectedApplicationId && applications) {
				// Find the selected application from the application list
				const selectedApplication = applications.find(
					(app) => app.applicationID === selectedApplicationId
				);
				if (selectedApplication) {
					const { wbsCode, costCenter } = selectedApplication.details;

					setFormValues('wbsCode', wbsCode || '');
					setFormValues('costCenter', costCenter || '');
					setCostCenterPlaceholder(costCenter || "N/A");
					setWbsCodePlaceholder(wbsCode || "N/A");
				}
			}
		});
	
		return () => subscription.unsubscribe();
	}, [applications, setFormValues, watch]);
	

	// useEffect(() => {
	// 	const getProjectWithTags = async (gcpProjectID: {
	// 		label: string
	// 		value: string
	// 	}) => {
	// 		let response = await api.gcpProjects.getSinglegcpProject(
	// 			gcpProjectID.value,
	// 			true
	// 		)
	// 		let project = response.body[0] as gcpProject
	// 		console.log(project)

	// 		setTagFormValues('platformName', project.tags?.details.platformName)
	// 		setTagFormValues('group', project.tags?.details.group)
	// 		setTagFormValues(
	// 			'fundingSourceType',
	// 			project.tags?.details.fundingSourceType
	// 		)
	// 		setTagFormValues('WBSCode', project.tags?.details.WBSCode)
	// 		setTagFormValues('costCenter', project.tags?.details.costCenter)
	// 		setTagFormValues('projectName', gcpProjectID.label)

	// 		setFormValues('wbsCode', project.tags?.details.WBSCode)
	// 		setFormValues('costCenter', project.tags?.details.costCenter)
	// 		setFormValues('group', project.tags?.details.group)
	// 		setValue('group', project.tags?.details.group)
	// 	}
	// 	if (isInheritTagsChecked) {
	// 		console.log(formValues.gcpProjectID)
	// 		getProjectWithTags(formValues.gcpProjectID)
	// 	} else {
	// 		clearTagFormValues()

	// 		setFormValues('wbsCode', '')
	// 		setFormValues('costCenter', '')
	// 		setFormValues('group', '')
	// 		setValue('group', '')
	// 	}
	// }, [
	// 	formValues.gcpProjectID,
	// 	setTagFormValues,
	// 	setValue,
	// 	isInheritTagsChecked,
	// 	clearTagFormValues,
	// 	setFormValues
	// ])

	// useEffect(() => {
	// 	if (tagFormValues.WBSCode !== '') {
	// 		setFormValues('wbsCode', tagFormValues.WBSCode)
	// 	}

	// 	if (tagFormValues.costCenter !== '') {
	// 		setFormValues('costCenter', tagFormValues.costCenter)
	// 	}
	// }, [tagFormValues.WBSCode, tagFormValues.costCenter, setFormValues])

	// useEffect(() => {
	// 	if (formValues.platformOwner !== '') {
	// 		setTagFormValues('platformOwner', formValues.platformOwner)
	// 	}

	// 	if (formValues.platformName !== '') {
	// 		setTagFormValues('platformName', formValues.platformName)
	// 	}

	// 	if (formValues.systemAlias !== '') {
	// 		setTagFormValues('systemAlias', formValues.systemAlias)
	// 	}
	// }, [formValues, setTagFormValues])

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return

		let tagsId = uuidv4()

		let newTag: TagsRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			approvalStatus: getTagsApprovalObject(),
			details: tagFormValues,
			action: 'CREATE',
			status: 'PENDING_APPROVAL'
		}

		const microservice: MicroserviceRequest = {
			requestorID: thisUserEmail,
			tagsID: tagsId,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}

		createTags(newTag, {
			onSuccess: () => {
				createNewMicroservice(microservice, {
					onSuccess: () => {
						navigate('/microservices')
						clearFormValues()
						clearTagFormValues()
						window.location.reload()
					},
					onError: (e) => console.error(e)
				})
			},
			onError: (e) => console.error(e)
		})
	}

	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [setValue])

	const handleYesClick = () => {
		setShowForm(true)
	}

	const handleNoClick = () => {
		navigate('/applications')
	}

	return (
		<div>
			{showForm ? (
				<Form
					actions={
						<SpaceBetween direction="horizontal" size="xs">
							<Box>
								<Button
									variant="primary"
									onClick={handleSubmit}
									loading={isLoading || isCreateTagsLoading}
								>
									Submit
								</Button>
								<Button variant="link" onClick={() => navigate(-1)}>
									Cancel
								</Button>
							</Box>
						</SpaceBetween>
					}
					header={
						<Header variant="h1" description="">
							Request a New Microservice
						</Header>
					}
				>
					<SpaceBetween direction="vertical" size="s">
						<Container>
							<SpaceBetween direction="vertical" size="l">
								<ColumnLayout columns={2} variant="default">
									<FormSelect
										label="Application Name"
										name="applicationName"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={applicationOptions}
										placeholder="Select Application"
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div style={{ width: '650px' }}>
											<FormSelect
												name="codeTemplate"
												label="Code Template"
												control={control}
												placeholder="Select Code Template"
												setFormValues={setFormValues}
												options={CODE_TEMPLATE_LIST}
												rules={{
													required: 'Please select a Code Template'
												}}
											/>
										</div>
										{/* <div style={{ marginLeft: '10px', marginTop: '25px' }}>
											<Popover
												fixedWidth
												header="Platform GCP Project ID tip"
												position="right"
												triggerType="custom"
												content={
													<p>
														Please see guide on how to enter your GCP Project
														ID:
														<a
															href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
															target="_blank"
															rel="noopener noreferrer"
															aria-label="Plus"
															style={{
																marginLeft: '10px',
																marginTop: '25px'
															}}
														>
															LINK
														</a>
													</p>
												}
											>
												<Icon name="status-info" />
											</Popover>
										</div> */}
									</div>
									<FormInput
										name="microserviceName"
										label="Name"
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Enter Display Name of Microservice"
										description="Max 20 characters"
										rules={{
											required: 'Please enter Microservice name',
											minLength: {
												value: 5,
												message: 'Minumum length of 5 characters'
											},
											pattern: {
												value: /^[a-z0-9]+$/,
												message:
													'Microservice name should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
									<div
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<div style={{ width: '650px' }}>
											<FormInput
												name="applicationUrl"
												label="Application URL Path"
												type="text"
												control={control}
												setFormValues={setFormValues}
												placeholder="Enter Application URL Path"
												description="The URL/path of application"
												rules={{
													required: 'Please enter application URL',
													minLength: {
														value: 5,
														message: 'Minumum length of 5 characters'
													}
												}}
											/>
										</div>
										{/* <div style={{ marginLeft: '10px', marginTop: '35px' }}>
											<Popover
												fixedWidth
												header="Platform GCP Project ID tip"
												position="right"
												triggerType="custom"
												content={
													<p>
														Please see guide on how to enter your GCP Project
														ID:
														<a
															href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
															target="_blank"
															rel="noopener noreferrer"
															aria-label="Plus"
															style={{
																marginLeft: '10px',
																marginTop: '25px'
															}}
														>
															LINK
														</a>
													</p>
												}
											>
												<Icon name="status-info" />
											</Popover>
										</div> */}
									</div>
									<FormInput
										name="portNumber"
										label="Port Number"
										type="text"
										control={control}
										setFormValues={setFormValues}
										placeholder="Enter Port Number"
										rules={{
											required: 'Please enter port number'
										}}
									/>
									<FormSelect
										label="Endpoint"
										name="microserviceEndpoint"
										control={control}
										rules={{
											required: 'Please Select Option'
										}}
										setFormValues={setFormValues}
										options={MICROSERVICE_ENDPOINT_LIST}
										placeholder="Select Microservice Endpoint"
									/>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<label style={{ fontWeight: '600', marginBottom: '5px' }}>
											WBS Code
										</label>
										<FormInput
												name="wbsCode"
												label=""
												type="text"
												description='Uses existing WBS Code from Application'
												disabled={true}
												control={control}
												setFormValues={setFormValues}
												placeholder={wbsCodePlaceholder}
												rules={{
													minLength: {
													  value: 3,
													  message: 'Minimum length should be 3'
													}
												  }}
											/>
										{/* <RadioGroup
											onChange={({ detail }) =>
												setWbsCode({
													wbsCode: detail.value,
													wbsCodeId: detail.value === 'useExisting' ? 0 : 1
												})
											}
											// name='wbsCode'
											value={wbsCode.wbsCode}
											items={[
												{ value: 'useExisting', label: 'Use Existing' },
												{ value: 'addNew', label: 'Add New' }
											]}
										/> */}
										{/* {wbsCode.wbsCodeId === 1 && (
											<FormInput
												name="wbsCode"
												label=""
												type="text"
												control={control}
												setFormValues={setFormValues}
												placeholder="Add new WBS Code"
												rules={{
													required: 'Please enter system alias',
													minLength: {
														value: 5,
														message: 'Minumum length of 5 characters'
													},
													pattern: {
														value: /^[a-z0-9]+$/,
														message:
															'Platform name should be lowercase only and whitespaces are not allowed.'
													}
												}}
											/>
										)} */}
									</div>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<label style={{ fontWeight: '600', marginBottom: '5px' }}>
											Cost Center
										</label>
										<FormInput
												name="costCenter"
												label=""
												type="text"
												description='Uses existing Cost Center from Application'
												disabled={true}
												control={control}
												setFormValues={setFormValues}
												placeholder={costCenterPlaceholder}
												rules={{
													minLength: {
														value: 3,
														message: 'Minimum length should be 3'
													}
												  }}
											/>
										{/* <RadioGroup
											onChange={({ detail }) =>(
												console.log(detail,'detail', costCenter.costCenterId),
												setCostCenter({
													costCenter: detail.value,
													costCenterId: detail.value === 'useExisting' ? 0 : 1
												}))
											}
											value={costCenter.costCenter}
											items={[
												{ value: 'useExisting', label: 'Use Existing' },
												{ value: 'addNew', label: 'Add New' }
											]}
										/> */}
										{/* {costCenter.costCenterId === 1 && (
											<FormInput
												name="costCenter"
												label=""
												type="text"
												control={control}
												setFormValues={setFormValues}
												placeholder="Add new Cost Center"
												rules={{
													required: 'Please enter system alias',
													minLength: {
														value: 5,
														message: 'Minumum length of 5 characters'
													},
													pattern: {
														value: /^[a-z0-9]+$/,
														message:
															'Platform name should be lowercase only and whitespaces are not allowed.'
													}
												}}
											/>
										)} */}
									</div>
								</ColumnLayout>
							</SpaceBetween>
						</Container>
					</SpaceBetween>
				</Form>
			) : (
				<div>
					<h2>Do you already have an application onboarded?</h2>
					<Button
						variant="primary"
						onClick={handleYesClick}
						loading={isLoading}
					>
						Yes
					</Button>

					<Button onClick={handleNoClick} loading={isLoading}>
						No
					</Button>
				</div>
			)}
		</div>
	)
}

export default NewMicroserviceForm
