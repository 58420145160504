import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { MICROSERVICES_API_URL } = CONSTANT

const getSingleMicroservice = async (
	microserviceID: string,
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${MICROSERVICES_API_URL}?microserviceID=${microserviceID}`
	)
	return response.json()
}

export default getSingleMicroservice
