import { produce } from 'immer'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type NewMicroserviceFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type NewMicroserviceFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

// Confirm on initialFormValues for microservice
// Commenting to update here
const initialFormValues = {
	// environment: '',
	// platformViewer: [],
	// region: 'asia-southeast1',
	// platformName: '',
	// platformOwner: '',
	// platformOwnerEmail: '',
	// systemAlias: '',
	// wbsCode: '',
	// costCenter: '',
	// group: '',
	// creationTime: '',
	// minNode: '',
	// maxNode: '',
	// machineType: ''

	applicationName: '',
	microserviceName: '',
	applicationUrl: '',
	portNumber: 0,
	microserviceEndpoint: '',
	// wbsCodeId: 0,
	wbsCode: '',
	// costCenterId: 0,
	costCenter: '',
	creationTime: ''
}

const newMicroserviceFormStore = create<
	NewMicroserviceFormState & NewMicroserviceFormActions
>((set, _get) => ({
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					// if (fieldName === 'platformViewer') {
					// 	const emails = value.split(',').map((email: string) => email.trim())
					// 	draft.formValues[fieldName] = emails
					// } else {
					// 	draft.formValues[fieldName] = value
					// }

					// if (
					// 	fieldName === 'applicationName'
					// ) {
					// 	draft.formValues[fieldName] = value.value
					// } else {
						draft.formValues[fieldName] = value
					// }
				})
			)
	}
}))

export const useNewMicroserviceFormStore = () =>
	newMicroserviceFormStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('NewMicroserviceFormStore', newMicroserviceFormStore)
}
