import { string } from "zod"

type BaseRequest = {
  action: 'CREATE' | 'REJECT' | 'APPROVE'
  resourceType: string
}

export type ProjectResourceApproval = {
  name: string
  status: number | null
  approverID: string
  lastUpdatedTime: string
}

export interface ProjectResourceRequest extends BaseRequest {
  status: 'PENDING_APPROVAL'
  requestorID: string
  creationTime?: string
  details: Record<any,any>
  approvalStatus: Array<ProjectResourceApproval>
}

export interface ProjectResourceRejectionRequest extends BaseRequest {
  projectResourceID: string
  commenterID: string
  comment: string
  timestamp: string
}

export interface ProjectResourceApprovalRequest extends BaseRequest {
  projectResourceID: string
  approverID: string
  details?: Record<any,any>
  timestamp: string
}

export type UpdateProjectResourceApprovalStatus = {
  action: 'UPDATE_APPROVAL'
  projectResourceID: string
  approvalStatus?: ProjectResourceApproval
}

type BaseResponse = {
  status: 'PENDING_APPROVAL' | 'APPROVED' | 'REJECTED' | 'ERROR'
  resourceType: string
  creationTime: string
}

export interface ProjectResource extends BaseResponse {
  projectResourceID: string
  requestorID: string
  details: Record<any,any>
  version?: Record<any,any>
  comments?: []
  approvalStatus: Array<ProjectResourceApproval>
}

export const getProjectResourceApprovalObject = (approvers: Array<string>) => {
  const approvalStatus = approvers.map((item) => {
    return {
      name: item,
      status: null,
      approverID: '',
      lastUpdatedTime: ''
    }
  })
  return approvalStatus
}