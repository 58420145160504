import {
	Box,
	Button,
	ColumnLayout,
	Container,
	Form,
	Header,
	SpaceBetween
} from '@cloudscape-design/components'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FormSelect from '../../../../../components/ControlledFormInputs/Select'
import { useForm } from 'react-hook-form'
import { useNewSslCertsFormStore } from '../../../../../stores/projects/projectResource/sslCerts/newSslCertsFormStore'
import FormInput from '../../../../../components/ControlledFormInputs/Input'
import useSingleProject from '../../../../../hooks/query/useSingleProject'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import {
	getProjectResourceApprovalObject,
	ProjectResourceRequest
} from '../../../../../types/projectResource'
import { useOktaAuth } from '@okta/okta-react'
import useCreateNewProjectResource from '../../../../../hooks/mutate/useCreateNewProjectResource'

type FormInputContainerProps = {
	children: ReactNode
}

function FormInputContainer(props: FormInputContainerProps) {
	return (
		<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
			<div style={{ width: '650px' }}>{props.children}</div>
		</div>
	)
}

let CERT_TYPE_OPTIONS = [
	{ label: 'Wildcard', value: 'wildcard' },
	{ label: 'Domain', value: 'domain' }
]

const NewSslCerts: FC = () => {
	const { id: projectId } = useParams() as { id: string }

	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()

	const { data: project, isLoading } = useSingleProject(projectId)

	const { mutate: createResource, isLoading: isSendLoading } =
		useCreateNewProjectResource()

	const {
		formValues: newSslCertFormValues,
		actions: { setFormValues: setNewSslCertFormValues, clearFormValues }
	} = useNewSslCertsFormStore()

	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: newSslCertFormValues
	})

	const [isWildcard, setIsWildCard] = useState(false)
	const [domainNameFieldRules, setDomainNameFieldRules] = useState({})

	useEffect(() => {
		setNewSslCertFormValues('projectID', projectId)
		setNewSslCertFormValues(
			'accountDistributionList',
			project?.details.distributionList
		)
		setNewSslCertFormValues('hostedZoneName', project?.approval?.details.dnsZoneApex)
		setValue('hostedZoneName', project?.approval?.details.dnsZoneApex)
	}, [projectId, project, setNewSslCertFormValues])

	useEffect(() => {
		setNewSslCertFormValues('certType', CERT_TYPE_OPTIONS[0])
		setValue('certType', CERT_TYPE_OPTIONS[0])
	}, [setNewSslCertFormValues, setValue])

	useEffect(() => {
		if (newSslCertFormValues.certType === 'domain') {
			setIsWildCard(false)
			setValue('domainName', '')
			setNewSslCertFormValues('domainName', '')
			setDomainNameFieldRules({
				pattern: {
					message: 'Should be lowercase letters and digits.',
					value: /([a-z-0-9])/
				}
			})
		} else if (newSslCertFormValues.certType === 'wildcard') {
			setIsWildCard(true)
			setValue('domainName', '*')
			setNewSslCertFormValues('domainName', '*')
			setDomainNameFieldRules({
				pattern: {
					message: 'Asterisk only allowed',
					value: /\*/
				}
			})
		}
	}, [newSslCertFormValues.certType, setNewSslCertFormValues, setValue])

	if (isLoading) {
		return <LoadingSpinner />
	}

	if (project === undefined) {
		return (
			<>
				<h1>Project Not Found</h1>
			</>
		)
	}

	const handleSubmit = async () => {
		await trigger()

		if (!isValid) {
			return false
		}

		let newProjectResource: ProjectResourceRequest = {
			status: 'PENDING_APPROVAL',
			requestorID: thisUserEmail,
			details: newSslCertFormValues,
			action: 'CREATE',
			resourceType: 'SSL_CERT',
			approvalStatus: getProjectResourceApprovalObject(['ISDP'])
		}

		console.log(newProjectResource)

		createResource(newProjectResource, {
			onSuccess: () => {
				clearFormValues()
				navigate(`/projects/${projectId}/sslCerts`)
			}
		})
	}

	return (
		<Form
			header={
				<Header variant="h1">
					New SSL Certificate for {project.details?.projectName}
				</Header>
			}
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isSendLoading}
						>
							Submit
						</Button>
						<Button
							variant="link"
							onClick={() => {
								navigate(`/projects/${projectId}/sslCerts`)
								clearFormValues()
							}}
							disabled={isSendLoading}
						>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<FormInputContainer>
							<FormSelect
								control={control}
								setFormValues={setNewSslCertFormValues}
								name="certType"
								label="Certificate Type"
								placeholder="Select Certificate Type"
								options={CERT_TYPE_OPTIONS}
								rules={{
									required: 'Please select a Certificate Type'
								}}
								disabled
							/>
						</FormInputContainer>

						<ColumnLayout columns={2}>
							<FormInputContainer>
								<FormInput
									control={control}
									setFormValues={setNewSslCertFormValues}
									name="domainName"
									label="Domain Name"
									placeholder="Enter Domain Name"
									disabled={isWildcard}
									rules={domainNameFieldRules}
								/>
							</FormInputContainer>
							
							<FormInputContainer>
								<FormInput
									control={control}
									setFormValues={setNewSslCertFormValues}
									name="hostedZoneName"
									label="Hosted Zone Apex Name"
									disabled
								/>
							</FormInputContainer>
						</ColumnLayout>


					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewSslCerts
