import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { APIS_API_URL } = CONSTANT

const getSingleApi = async (
	apiID: string
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${APIS_API_URL}?apiID=${apiID}`
	)
	return response.json()
}

export default getSingleApi
