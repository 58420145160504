import { produce } from "immer"
import { create } from "zustand"

type NewSslCertsFormState = {
  isFormValid: boolean
  formValues: Record<string,any>
}

type NewSslCertsFormActions = {
  actions: {
    clearFormValues: () => void
    setFormValues: (fieldName: string, value: any) => void
    setFormValidity: (isValid: boolean) => void
  }
}

const initialFormValues = {
  certType: '',
  domainName: '',
  hostedZoneName: '',
  projectID: '',
  accountDistributionList: ''
}

const newSslCertsFormStore = create<
  NewSslCertsFormState & NewSslCertsFormActions
  >((set, _get) => ({
    isFormValid: false,
    formValues: initialFormValues,
    actions: {
      clearFormValues: () => set(() => ({formValues: initialFormValues})),
      setFormValidity: (isValid: boolean) => set(() => ({isFormValid: isValid})),
      setFormValues: (fieldName: string, value: any) =>
        set((state) => 
          produce(state, (draft) => {
            draft.formValues[fieldName] = value

            if (fieldName === 'certType') {
              draft.formValues[fieldName] = value.value
            }
          })
        )
    }
  }))

export const useNewSslCertsFormStore = () => newSslCertsFormStore((state) => state)