// import { FC, useEffect, useState } from 'react'
// import { useOktaAuth } from '@okta/okta-react'
// import { useForm } from 'react-hook-form'
// import Icon from '@cloudscape-design/components/icon'
// import {
// 	Button,
// 	Container,
// 	ExpandableSection,
// 	Form,
// 	Popover,
// 	SpaceBetween
// } from '@cloudscape-design/components'

// import FormInput from '../../ControlledFormInputs/Input'
// import { useApprovalFormStore } from '../../../stores/microservices/approvalFormStore'
// import type { MicroserviceApprovalRequest } from '../../../types/microservices'
// import useAcceptMicroservice from '../../../hooks/mutate/useAcceptMicroservice'
// import useSingleMicroservice from '../../../hooks/query/useSingleMicroservice'
// import { ColumnLayout } from '@cloudscape-design/components'
// import acceptMicroservice from '../../../api/microservices/acceptMicroservice'

// interface ApprovalFormProps {
// 	thisMicroserviceID: string
// 	shouldShowApprovalForm: (shouldShow: boolean) => void
// }

// const ApprovalForm: FC<ApprovalFormProps> = ({
// 	thisMicroserviceID,
// 	shouldShowApprovalForm
// }) => {
// 	const { authState } = useOktaAuth()
// 	const thisUserEmail = authState?.idToken?.claims.email as string

// 	const {
// 		formValues,
// 		actions: { setFormValues, clearFormValues }
// 	} = useApprovalFormStore()

// 	useEffect(() => {
// 		const approvervalue = thisUserEmail
// 		setFormValues('approverID', approvervalue)
// 	}, [thisUserEmail])

// 	const {
// 		control,
// 		trigger,
// 		setValue,
// 		formState: { isValid }
// 	} = useForm({
// 		mode: 'onChange',
// 		reValidateMode: 'onChange',
// 		defaultValues: formValues
// 	})

// 	const { mutate: acceptMicroservice, isLoading } =
// 		useAcceptMicroservice(thisMicroserviceID)

// 	const { data: thisMicroservice, isLoading: isMicroserviceLoading } =
// 		useSingleMicroservice(thisMicroserviceID)

// 	const _handleApprove = () => {
// 		trigger()

// 		if (!isValid) return

// 		const approvalRequest: MicroserviceApprovalRequest = {
// 			action: 'APPROVE',
// 			microserviceID: thisMicroserviceID,
// 			approverID: thisUserEmail,
// 			details: formValues
// 		}
// 		acceptMicroservice(approvalRequest, {
// 			onSuccess: () => {
// 				shouldShowApprovalForm(false)
// 				clearFormValues()
// 				window.location.reload()
// 			}
// 		})
// 	}

// 	// Commenting to update here
// 	// useEffect(() => {
// 	// 	if (thisPlatform?.environment === 'dev') {
// 	// 		setValue('networkProjectID', 'glo-ntwksvcs-d-vg11')
// 	// 		setFormValues('networkProjectID', 'glo-ntwksvcs-d-vg11')
// 	// 		setValue('network', 'glo-d-net-vpc-de31')
// 	// 		setFormValues('network', 'glo-d-net-vpc-de31')
// 	// 		setValue('argocdNamespace', 'ccm-dev')
// 	// 		setFormValues('argocdNamespace', 'ccm-dev')
// 	// 	} else if (thisPlatform?.environment === 'prod') {
// 	// 		setValue('networkProjectID', 'glo-ntwksvcs-p-vg01')
// 	// 		setFormValues('networkProjectID', 'glo-ntwksvcs-p-vg01')
// 	// 		setValue('network', 'glo-p-net-vpc-pr21')
// 	// 		setFormValues('network', 'glo-p-net-vpc-pr21')
// 	// 		setValue('argocdNamespace', 'ccm-prod')
// 	// 		setFormValues('argocdNamespace', 'ccm-prod')
// 	// 	} else if (thisPlatform?.environment === 'staging') {
// 	// 		setValue('networkProjectID', 'glo-ntwksvcs-st-vg21')
// 	// 		setFormValues('networkProjectID', 'glo-ntwksvcs-st-vg21')
// 	// 		setValue('network', 'glo-st-net-vpc-st41')
// 	// 		setFormValues('network', 'glo-st-net-vpc-st41')
// 	// 		setValue('argocdNamespace', 'ccm-stg')
// 	// 		setFormValues('argocdNamespace', 'ccm-stg')
// 	// 	}
// 	// 	setValue('region', 'Singapore (asia-southeast1)')
// 	// 	const reg =
// 	// 		'glo-' +
// 	// 		thisPlatform?.group +
// 	// 		'-' +
// 	// 		thisPlatform?.systemAlias +
// 	// 		'-' +
// 	// 		thisPlatform?.environment +
// 	// 		'-gke'
// 	// 	setValue('namePrefix', reg)
// 	// 	setFormValues('namePrefix', reg)
// 	// 	setValue('releaseChannel', 'STABLE')
// 	// 	setFormValues('releaseChannel', 'STABLE')
// 	// }, [thisPlatform?.environment])

// 	// Commenting to update here
// 	return (
// 		<Form
// 			actions={
// 				<SpaceBetween direction="horizontal" size="xs">
// 					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
// 						Cancel
// 					</Button>
// 					<Button
// 						variant="primary"
// 						loading={isLoading}
// 						onClick={_handleApprove}
// 					>
// 						Approve
// 					</Button>
// 				</SpaceBetween>
// 			}
// 		>
// 			<Container>
// 				<SpaceBetween direction="vertical" size="l">
// 					<ColumnLayout columns={2} variant="default">
// 						<FormInput
// 							name="namePrefix"
// 							label="Cluster Name Prefix"
// 							control={control}
// 							setFormValues={setFormValues}
// 							rules={{
// 								required: 'Cluster Name Prefix is required',
// 								pattern: {
// 									value: /^[a-z0-9-_]+(?:\s*[a-z0-9-_]+)*$/,
// 									message: 'Uppercase letters, whitespaces are not allowed.'
// 								}
// 							}}
// 							placeholder="Enter Prefix for your Cluster Name"
// 							disabled={true}
// 						/>

// 						<FormInput
// 							name="networkProjectID"
// 							label="Network Project ID"
// 							control={control}
// 							setFormValues={setFormValues}
// 							rules={{
// 								required: 'Network Project ID is required'
// 							}}
// 							disabled={true}
// 							placeholder="Enter Network Project ID"
// 						/>
// 						<FormInput
// 							name="network"
// 							label="VPC Name"
// 							control={control}
// 							setFormValues={setFormValues}
// 							rules={{
// 								required: 'VPC Name is required'
// 							}}
// 							disabled={true}
// 							placeholder="Enter VPC Name"
// 						/>
// 						<FormInput
// 							name="subnetwork"
// 							label="Subnet Name"
// 							control={control}
// 							setFormValues={setFormValues}
// 							rules={{
// 								required: 'Subnet Name is required',
// 								pattern: {
// 									value: /^[a-z0-9-_]+(?:\s*[a-z0-9-_]+)*$/,
// 									message: 'Uppercase letters, whitespaces are not allowed.'
// 								}
// 							}}
// 							placeholder="Enter Subnet Name"
// 						/>
// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								flexWrap: 'wrap'
// 							}}
// 						>
// 							<div style={{ flex: 1, minWidth: '250px' }}>
// 								<FormInput
// 									name="ipRangePods"
// 									label="Name of Secondary range for Pods"
// 									control={control}
// 									setFormValues={setFormValues}
// 									rules={{
// 										required: 'Secondary Range Name for Pods is required'
// 									}}
// 									placeholder="Enter Name of Secondary range for Pods"
// 								/>
// 							</div>
// 							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
// 								<Popover
// 									fixedWidth
// 									header="Name of Secondary range for Pods tool tip"
// 									position="right"
// 									triggerType="custom"
// 									content={
// 										<p>
// 											Please see guide on how to fill the Secondary range for
// 											Pods:
// 											<a
// 												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
// 												target="_blank"
// 												rel="noopener noreferrer"
// 												aria-label="Plus"
// 												style={{
// 													marginLeft: '5px',
// 													marginTop: '25px'
// 												}}
// 											>
// 												LINK
// 											</a>
// 										</p>
// 									}
// 								>
// 									<Icon name="status-info" />
// 								</Popover>
// 							</div>
// 						</div>

// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								flexWrap: 'wrap'
// 							}}
// 						>
// 							<div style={{ flex: 1, minWidth: '250px' }}>
// 								<FormInput
// 									name="ipRangeServices"
// 									label="Name of Secondary range for Services"
// 									control={control}
// 									setFormValues={setFormValues}
// 									rules={{
// 										required: 'Secondary Range Name for Services is required'
// 									}}
// 									placeholder="Enter Name of Secondary range for Services"
// 								/>
// 							</div>
// 							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
// 								<Popover
// 									fixedWidth
// 									header="Name of Secondary range for Services tool tip"
// 									position="right"
// 									triggerType="custom"
// 									content={
// 										<p>
// 											Please see guide on how to fill the Secondary range for
// 											Services:
// 											<a
// 												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
// 												target="_blank"
// 												rel="noopener noreferrer"
// 												aria-label="Plus"
// 												style={{
// 													marginLeft: '5px',
// 													marginTop: '25px'
// 												}}
// 											>
// 												LINK
// 											</a>
// 										</p>
// 									}
// 								>
// 									<Icon name="status-info" />
// 								</Popover>
// 							</div>
// 						</div>

// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								flexWrap: 'wrap'
// 							}}
// 						>
// 							<div style={{ flex: 1, minWidth: '250px' }}>
// 								<FormInput
// 									name="subnetworkRange"
// 									label="Node Subnet Range"
// 									control={control}
// 									setFormValues={setFormValues}
// 									rules={{
// 										required: 'Node Subnet Range is required',
// 										pattern: {
// 											value: /^[0-9./]*$/,
// 											message: 'Only numbers, / and . is allowed'
// 										}
// 									}}
// 									placeholder="Enter Node Subnet Range"
// 								/>
// 							</div>
// 							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
// 								<Popover
// 									fixedWidth
// 									header="Node Subnet Range tool tip"
// 									position="right"
// 									triggerType="custom"
// 									content={
// 										<p>
// 											Please see guide on how to fill the Node Subnet Range:
// 											<a
// 												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
// 												target="_blank"
// 												rel="noopener noreferrer"
// 												aria-label="Plus"
// 												style={{
// 													marginLeft: '5px',
// 													marginTop: '25px'
// 												}}
// 											>
// 												LINK
// 											</a>
// 										</p>
// 									}
// 								>
// 									<Icon name="status-info" />
// 								</Popover>
// 							</div>
// 						</div>

// 						<div
// 							style={{
// 								display: 'flex',
// 								alignItems: 'center',
// 								flexWrap: 'wrap'
// 							}}
// 						>
// 							<div style={{ flex: 1, minWidth: '250px' }}>
// 								<FormInput
// 									name="controlPlaneRange"
// 									label="Control Plane Range"
// 									control={control}
// 									setFormValues={setFormValues}
// 									rules={{
// 										required: 'Control Panel Range is required',
// 										pattern: {
// 											value: /^[0-9./]*$/,
// 											message: 'Only numbers, / and . is allowed'
// 										}
// 									}}
// 									placeholder="Enter Control Plane Range"
// 								/>
// 							</div>
// 							<div style={{ marginLeft: '3px', marginTop: '25px' }}>
// 								<Popover
// 									fixedWidth
// 									header="Control Plane Range tool tip"
// 									position="right"
// 									triggerType="custom"
// 									content={
// 										<p>
// 											Please see guide on how to fill the Control Plane Range:
// 											<a
// 												href="https://globetelecom.atlassian.net/wiki/x/roAHLQE"
// 												target="_blank"
// 												rel="noopener noreferrer"
// 												aria-label="Plus"
// 												style={{
// 													marginLeft: '5px',
// 													marginTop: '25px'
// 												}}
// 											>
// 												LINK
// 											</a>
// 										</p>
// 									}
// 								>
// 									<Icon name="status-info" />
// 								</Popover>
// 							</div>
// 						</div>

// 						<FormInput
// 							name="ccmClusterName"
// 							label="CCM Cluster Name"
// 							type="text"
// 							control={control}
// 							setFormValues={setFormValues}
// 							placeholder=""
// 							disabled={true}
// 						/>

// 						<FormInput
// 							name="ccmProjectId"
// 							label="CCM Project ID"
// 							type="text"
// 							control={control}
// 							setFormValues={setFormValues}
// 							placeholder=""
// 							disabled={true}
// 						/>

// 						<FormInput
// 							name="region"
// 							label="Region"
// 							type="text"
// 							control={control}
// 							setFormValues={setFormValues}
// 							description="Region of your GKE Cluster (by default Singapore)"
// 							disabled={true}
// 						/>

// 						<FormInput
// 							label="Argocd Namespace"
// 							name="argocdNamespace"
// 							control={control}
// 							rules={{
// 								required: 'Argocd Namespace is required'
// 							}}
// 							setFormValues={setFormValues}
// 							disabled={true}
// 						/>
// 					</ColumnLayout>
// 					<ExpandableSection headerText="Optional Input(s)">
// 						<SpaceBetween direction="vertical" size="l">
// 							<FormInput
// 								label="Release Channel"
// 								name="releaseChannel"
// 								control={control}
// 								rules={{
// 									required: 'Release Channel is required'
// 								}}
// 								setFormValues={setFormValues}
// 								disabled={true}
// 							/>
// 							<FormInput
// 								name="kVersion"
// 								label="Kubernetes Version"
// 								control={control}
// 								description="Optional | Enter the latest version of Kubernetes"
// 								setFormValues={setFormValues}
// 								placeholder="Enter Kubernetes Version"
// 							/>
// 						</SpaceBetween>
// 					</ExpandableSection>
// 				</SpaceBetween>
// 			</Container>
// 		</Form>
// 	)
// }

// export default ApprovalForm


import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { Button, Form, SpaceBetween, Alert, Container } from '@cloudscape-design/components'
import useAcceptMicroservice from '../../../hooks/mutate/useAcceptMicroservice'
import { useApprovalFormStore } from '../../../stores/microservices/approvalFormStore'
import type { MicroserviceApprovalRequest } from '../../../types/microservices'
import useSingleMicroservice from '../../../hooks/query/useSingleMicroservice'
// import { ColumnLayout } from '@cloudscape-design/components'
import acceptMicroservice from '../../../api/microservices/acceptMicroservice'

interface ApprovalFormProps {
	thisMicroserviceID: string
	shouldShowApprovalForm: (shouldShow: boolean) => void
}

const ApprovalForm: FC<ApprovalFormProps> = ({
	thisMicroserviceID,
	shouldShowApprovalForm
}) => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string
	const { mutate: acceptMicroservice, isLoading: isAccepting } = useAcceptMicroservice(thisMicroserviceID)
	const navigate = useNavigate()

	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useApprovalFormStore()

	useEffect(() => {
		const approvervalue = thisUserEmail
		setFormValues('approverID', approvervalue)
	}, [thisUserEmail])

	const handleApprove = () => {
		const approvalRequest: MicroserviceApprovalRequest = {
			action: "APPROVE",
			microserviceID: thisMicroserviceID,
			approverID: thisUserEmail,
			details: formValues
		}
		acceptMicroservice(approvalRequest, {
			onSuccess: () => {
				shouldShowApprovalForm(false)
				// clearFormValues()
				// navigate('/microservices')
				window.location.reload()
			}
		});
	};

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Button variant="link" onClick={() => shouldShowApprovalForm(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						loading={isAccepting}
						onClick={handleApprove}
					>
						Approve
					</Button>
				</SpaceBetween>
			}
			>
			<Container>
				<Alert
					statusIconAriaLabel="Info"
					header="Are you sure you want to approve this request?"
					>
					Review the request details.
				</Alert>
			</Container>				
		</Form>
	)
}

export default ApprovalForm